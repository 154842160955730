import React from 'react';

class LastLoaded extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }
   
    render(){
        const { fanData } = this.props;
        return (
            <div>
              <span className="queryType">
                {fanData.query}
              </span>
              <span className="lastChecked">
                Updated last: {`${new Date(fanData.timestamp).toLocaleDateString()} ${new Date(fanData.timestamp).toLocaleTimeString()}`}
              </span>
            </div>
          );
    }
}

export default LastLoaded;