import React from 'react';

const Header = ({isLoaded}) =>  {
    return (
        <header className={`App-header`}>
            <img className="logo" src="logo192.png" alt="Logo" />
            <div>
                <h1>Are You Legendary?</h1>
                <h5>Find out how many Strava fans you have, and who your biggest fans are!</h5>  
                <br/>
            </div>
        </header>
    );
};
export default Header;