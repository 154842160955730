import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ordinal from 'ordinal-number-suffix';

class Fans extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }
    renderFanGroupRow(rowItems, i, group, icon){
        const count = rowItems.length;
        const oddRow = rowItems.length % 2 !== 0;
        const xs = (index) => {
            if(count < 6)
                return (oddRow && index === count-1) ? 12 : 6;
            else 
                return (oddRow && index >= 3) ? 6 : 6;
        }
        const sm = () => count < 6 ? 12/count : 0;
        const md = () => 12/count;
        const lg = () => 12/count;
        return (
          <div key={i}>
            <Row className="fan-group others" key={i}>
              {rowItems.map((item, index) => (
    
                <Col className="fan-col" xs={xs(index)} sm={sm()} md={md()} lg={lg()} key={item.first + item.last}>
                <div className={`fan ${group}`}>
                  
                  <span className="icon">
                    <FontAwesomeIcon icon={icon} />
                  </span>
                  
                  <div className="name">
                    <span className="text">{item.first} {item.last}</span>
                  </div> 
                  <div className="right">
                    <span className="place">
                      <span className="number">{ordinal(item.place)}</span>
                      <span className="text">Place</span>
                    </span>
                    <div className="kudos">
                      <span className="count">{item.count}</span>
                      <span className="text">Kudos</span>
                    </div>
                  </div>
                  <span className="date">
                    Last kudos: <br/>{new Date(item.date).toDateString()}
                  </span>
                  <span className="activity">
                    <a href={`https://www.strava.com/activities/${item.activityId}`} target="activity">{item.activity}</a>
                  </span>
                </div>
              </Col>
              ))}
            </Row>
          </div>
        );
    }
    renderPodiumRow(rowItems, i){
        return (
          <div key={i}>
            <Row className="fan-group podium">
            {rowItems.map((item, place) => (
              <Col className="fan-col" xs="12" sm={item.place === 1 ? "12":"6"} key={item.first + item.last}>
                <div className="place">
                  {item.place === 1 && <FontAwesomeIcon icon="crown" />}
                  {item.place > 1 && <FontAwesomeIcon icon="trophy" />}
                  <span className={`place-number place-${item.place}`}>{item.place}</span>
                </div>
                <div className="fan">
                  <span className="title">
                  {item.place === 1 && <span>Your Biggest Fan!</span> }
                  {item.place === 2 && <span>2nd Place</span> }
                  {item.place === 3 && <span>3rd Place</span>}
                  </span>
                  <span className="icon">
                    {item.place === 1 && <FontAwesomeIcon icon="crown" />}
                    {item.place > 1 && <FontAwesomeIcon icon="trophy" />}
                  </span>
                  <span className="name">
                    {item.first} {item.last}
                  </span> 
                  <div className="right">
                    <div className="kudos">
                      <span className="count">
                      {item.count}
                      </span>
                      <span className="text">
                        Kudos
                      </span>
                    </div>
                  </div>
                  <span className="date">
                    Last kudos: {new Date(item.date).toDateString()}
                  </span>
                  <span className="activity">
                      <a href={`https://www.strava.com/activities/${item.activityId}`} target="activity">{item.activity}</a>
                  </span>
                </div>
              </Col>
            ))}
          </Row>       
        </div>
        );
    }
     
    render(){
        const { fanData } = this.props;
        return (
            <div className="results">
                <div className="fans">
                {fanData && fanData.fans && fanData.fans.length > 0 &&
                    <span className="section-title">Fan Podium</span>
                }
                {fanData && fanData.fans && fanData.fans.slice(0,3).chunk(3).map((chunk, i) => 
                    this.renderPodiumRow(chunk, i))
                }
                {fanData && fanData.fans && fanData.fans.length > 3 &&
                    <span className="section-title">Top 10 Fans</span>
                }
                {fanData && fanData.fans && fanData.fans.slice(3, 10).chunk(4).map((chunk, i) => 
                    this.renderFanGroupRow(chunk, i, "first-tier", "medal"))
                }
                {fanData && fanData.fans && fanData.fans.length > 10 &&
                    <span className="section-title">Top 50 Fans</span>
                }
                {fanData && fanData.fans && fanData.fans.slice(10, 50).chunk(4).map((chunk, i) => 
                    this.renderFanGroupRow(chunk, i, "second-tier", "award"))
                }
                {fanData && fanData.fans && fanData.fans.length > 50 &&
                    <span className="section-title">Top 100 Fans</span>
                }
                {fanData && fanData.fans && fanData.fans.slice(50, 100).chunk(4).map((chunk, i) => 
                    this.renderFanGroupRow(chunk, i, "third-tier", "star"))
                }
                {fanData && fanData.fans && fanData.fans.length > 100 &&
                    <span className="section-title">Questionable Friends</span>
                }
                {fanData && fanData.fans && fanData.fans.slice(100, 150).chunk(6).map((chunk, i) => 
                    this.renderFanGroupRow(chunk, i, "fourth-tier", "bomb"))
                }
                {fanData && fanData.fans && fanData.fans.length > 150 &&
                    <span className="section-title">Do you even know these people?</span>
                }
                {fanData && fanData.fans && fanData.fans.slice(150).chunk(6).map((chunk, i) => 
                    this.renderFanGroupRow(chunk, i, "fifth-tier", "dizzy"))
                }
                </div>
            </div>
              
        );
    }
}

export default Fans;