import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Cookies } from 'react-cookie';
import MostPopularActivities from '../Components/MostPopularActivities';
import TotalKudos from '../Components/TotalKudos';
import LastLoaded from '../Components/LastLoaded';
import AthleteProfile from '../Components/AthleteProfile';
import Fans from '../Components/Fans';
import Connection from '../Components/Connection';
import LegendStatus from '../Components/LegendStatus';

class LegendStatusDashboard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoading: false,
        isLoaded: false,
        fanData: null,
        athleteData: null,
        rangeQuery: 'last=100'
      };
    }
  
    componentDidMount() {
      const { rangeQuery } = this.state;
      this.getAthlete();
      this.getSavedFans(rangeQuery);
    }
    getAthlete(){
      const savedAthlete = this.getSavedAthlete();
      if(!savedAthlete){
        this.fetchAthlete();
      }
    }
  
    getFans(){
      const { rangeQuery } = this.state;
      const WAITTIME_MINUTES = 15;
      const lastFetch = JSON.parse(localStorage.getItem('last_fetch'));
      if(lastFetch){
        const secondsSinceLast = ((new Date().getTime() - new Date(lastFetch).getTime()) / 1000);
        if(secondsSinceLast < 60 * WAITTIME_MINUTES){
          this.setState({ error: { message: `You can only update once every ${WAITTIME_MINUTES} minutes. Try again in a few minutes.` }});
          return;
        }
      }
      this.fetchAthlete();
      this.fetchFans(rangeQuery);
  
    }
  
    async fetchAthlete(){
      const cookies = new Cookies();
      const token = cookies.get('auth-token');
      if(token){
        try{
          const res = await fetch(`/api/athlete`, {
            headers: {
              "auth-token": JSON.stringify(token)
            }
          });
          const data = await res.json();
          if(res.status === 401){
            cookies.remove('auth-token');
            window.location.href = '/';
          }
          if(res.ok){
            localStorage.setItem('athlete', JSON.stringify(data));
            this.setState({
              //isLoading: false,
              athleteData: data,
              error: null
            });
          }
          else{
            throw new Error(data.message);
          }
        }
        catch(error){
          this.setState({
            //isLoading: false,
            error
          });
        }
      }
    }
    async fetchFans(rangeQuery) {
      const cookies = new Cookies();
      const token = cookies.get('auth-token');
      if(token){
        this.setState({ isLoading: true }, async () => {
          try{
            const res = await fetch(`/api/fans${rangeQuery ? '?' + rangeQuery : ''}`, {
              headers: {
                "auth-token": JSON.stringify(token)
              }
            });
            const data = await res.json();
            if(res.status === 401){
              cookies.remove('auth-token');
              window.location.href = '/';
            }
            if(res.ok){
              localStorage.setItem(`last_fetch`, JSON.stringify(data.timestamp));
              localStorage.setItem(`fans_${rangeQuery}`, JSON.stringify(data));
              this.setState({
                isLoading: false,
                isLoaded: true,
                fanData: data,
                error: null
              });
            }
            else{
              throw new Error(data.message);
            }
          }
          catch(error){
            this.setState({
              isLoading: false,
              isLoaded: true,
              error
            });
          }
        });
      }
    }
  
    getSavedAthlete(){
      const athlete = localStorage.getItem('athlete');
      let parsedAthlete = null;
      if(athlete){
        parsedAthlete = JSON.parse(athlete);
        this.setState({
          isLoading: false,
          athleteData: parsedAthlete,
          error: null
        });
      }
      return parsedAthlete;
    }
  
    getSavedFans(rangeQuery){
      const fans = localStorage.getItem(`fans_${rangeQuery}`);
      let parsedFans = null;
      if(fans){
        parsedFans = JSON.parse(fans);
        this.setState({
          isLoading: false,
          isLoaded: true,
          fanData: parsedFans,
          error: null
        });
      }
      else{
        this.setState({
          isLoading: false,
          isLoaded: false,
          fanData: null,
          error: null
        });
      }
      return parsedFans;
    }
    onResize(width, height){
      this.setState({ width: width });
    }
  
    setRangeQuery(rangeQuery){
      this.getSavedFans(rangeQuery);
      this.setState({ rangeQuery });
    }
    
    render() {
      const cookies = new Cookies();
      const token = cookies.get('auth-token');
      const { error, isLoading, isLoaded, fanData, athleteData, rangeQuery } = this.state;
      
      return (
        <div>
            <ReactResizeDetector handleWidth handleHeight onResize={this.onResize.bind(this)} />
       
            {athleteData &&
              <div className="athlete">
                <AthleteProfile athleteData={athleteData} />
              </div>
            }
            <div>
              <Connection 
                onSetRangeQuery={this.setRangeQuery.bind(this)}
                onGetFans={this.getFans.bind(this)}
                hasToken={!!token} error={error} isLoading={isLoading} isLoaded={isLoaded} rangeQuery={rangeQuery} 
              />
            </div>
            
            {fanData &&
              <div>
                <LegendStatus fanData={fanData} />
                <LastLoaded fanData={fanData} />
                <TotalKudos fanData={fanData} />
                <MostPopularActivities fanData={fanData} />
                <Fans fanData={fanData} />
              </div>
            }
            
        </div>
      );
    
    }
}

export default LegendStatusDashboard;