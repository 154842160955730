import React from 'react';
import poweredBy from '../content/pwrdBy_strava_light/api_logo_pwrdBy_strava_horiz_light.svg';

const Footer = () => {
    return (
        <footer>
            <img src={poweredBy} className="poweredBy" alt="logo" />  
            <span className="copyright">Copyright &copy; 2023 @iambillygoat</span>
            <span className="disclaimer">* Results may not be accurate because of Strava data privacy limitations</span>
      </footer>
    );
}

export default Footer;