import React from 'react';
import { Row, Col } from 'reactstrap';

class LegendStatus extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }
   
    renderLabel(){
        const { fanData } = this.props;
        const totalActivities = fanData.activities.length;
        const kudosPerActivity = Math.round((fanData.total / totalActivities)*10)/10;
        const totalFans = fanData.fans.length;
        console.log("kudosPerActivity:", kudosPerActivity);
        console.log("totalFans:", totalFans);
        if (kudosPerActivity <= 1 || totalFans <= .2)
          return <span>Noob</span>;
        else if (kudosPerActivity <= 2.5 || totalFans <= 5)
          return <span>Introvert</span>;
        else if (kudosPerActivity <= 5 || totalFans <= 1) 
          return <span>Beginner</span>;
        else if (kudosPerActivity <= 10 || totalFans <= 1.5)
          return <span>Wannabe</span>;
        else if(kudosPerActivity <= 15 || totalFans <= 2.5) 
          return <span>Up and Comer</span>;
        else if (kudosPerActivity <= 20 || totalFans <= 3) 
          return <span>Saucy</span>;
        else if (kudosPerActivity <= 25 || totalFans <= 3.5)
          return <span>Celebrity</span>;
        else if (kudosPerActivity >= 30 || totalFans >= 4)
          return <span>Legendary!</span>;
        else if (kudosPerActivity >= 50 || totalFans >= 6) 
          return <span>Attention Hoarder!</span>;
    }

    render(){
        return (
            <Row>
                <Col>
                    <div className="legend-status animated bounce">{this.renderLabel()}</div>
                </Col>
            </Row>
        );
    }
}

export default LegendStatus;