import React from 'react';

function About() {

    return (
        <div>
            <h2>About</h2>
      </div>
    );
}

export default About;