import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';


import 'chart.js';
import { faTrophy, faStar, faCrown, faThumbsUp, faMedal, faUser, faAward, faBomb, faDizzy, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './App.scss';
import { applyArrayExtensions, applyDateExtensions } from './utils';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  //Link
} from "react-router-dom";

import Home from './Pages/Home';
import About from './Pages/About';

applyArrayExtensions();
applyDateExtensions();

library.add(faTrophy, faStar, faCrown, faThumbsUp, faMedal, faUser, faAward, faBomb, faDizzy, faExclamationTriangle );

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

function App() {
  
  return (
    <Router>
      <div>
        { /*<ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>

        <hr /> */}

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
