import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TotalKudos extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }
   
    render(){
      const { fanData } = this.props;
      return (
        <Row>
          <Col xs="12" md="6">
          <div className="total right">
            <FontAwesomeIcon icon="thumbs-up" /> {fanData.total} Kudos
            <span className="total-subheader">{Math.round(fanData.total/fanData.activities.length)} Kudos/Activity (Avg)</span>
          </div>
          </Col>
          <Col xs="12" md="6">
            <div className="total">
              <FontAwesomeIcon icon="user" /> {fanData && fanData.fans && fanData.fans.length} Fans
              <span className="total-subheader">{Math.round(fanData.total/fanData.fans.length)} Kudos/Fan (Avg)</span>
            </div>
          </Col>
        </Row>
      );
    }
}

export default TotalKudos;