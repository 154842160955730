import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LineChart, ColumnChart } from 'react-chartkick';

class MostPopularActivities extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }
    calculateChartData(fanData){
        const metricsPerDayOfWeek = Date.getDaysOfWeek();

        let maxKudos = 0; 
        let maxPtSize = 8;
    
        const activityKudosChart = {
          data: {},
          dataset: { 
            pointRadius: [], 
            pointHoverRadius: [],
            pointBorderColor: [] 
          },
          callbacks: {
            title: (items, data) => fanData.activities[items[0].index].activity,
            beforeBody: (items, data) => {
              const date = new Date(fanData.activities[items[0].index].date.replace('Z', ''));
              return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} (${date.getDayOfWeek()})`;
            },
            label: (item, obj) => `${item.yLabel} kudos`
          }
        };

        const activityChart = {
          data: {},
          dataset: { 
            pointRadius: [], 
            pointHoverRadius: [],
            pointBorderColor: [] 
          },
          callbacks: {
            title: (items, data) => fanData.activities[items[0].index].activity,
            beforeBody: (items, data) => {
              const date = new Date(fanData.activities[items[0].index].date.replace('Z', ''));
              return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} (${date.getDayOfWeek()})`;
            },
            label: (item, obj) => {
              let unit = `${activityChart.data[item.datasetIndex].unit}`;
              let value = item.yLabel;
              if(unit === 'minutes'){
                unit = '';
                value = Date.formatTimespan(value*60);
              }
              return `${value} ${unit}`;
            }
          }
        };

        const activityPerformanceChart = {
          data: {},
          dataset: { 
            pointRadius: [], 
            pointHoverRadius: [],
            pointBorderColor: [] 
          },
          callbacks: {
            title: (items, data) => fanData.activities[items[0].index].activity,
            beforeBody: (items, data) => {
              const date = new Date(fanData.activities[items[0].index].date.replace('Z', ''));
              return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} (${date.getDayOfWeek()})`;
            },
            label: (item, obj) => {
              let unit = `${activityPerformanceChart.data[item.datasetIndex].unit}`;
              let value = item.yLabel;
              if(unit === 'minutes'){
                unit = '';
                value = Date.formatTimespan(value*60);
              }
              return `${value} ${unit}`;
            }
          }
        };
    
        const dayOfWeekKudosChart = {
          data: Date.getDaysOfWeek(),
          callbacks: {
            title: (items, data) => `${items[0].xLabel} activities`,
            beforeBody: (items, data) => `${metricsPerDayOfWeek[items[0].xLabel].activities} activities, ${metricsPerDayOfWeek[items[0].xLabel].kudos} total kudos`,
            label: (item, obj) => `Avg kudos per activity: ${item.yLabel}`
          }
        };
    
        const dayOfWeekActivitiesChart = {
          data: Date.getDaysOfWeek(),
          callbacks: {
            title: (items, data) => `${items[0].xLabel} activities`,
            beforeBody: (items, data) => `${metricsPerDayOfWeek[items[0].xLabel].activities} activities, ${metricsPerDayOfWeek[items[0].xLabel].kudos} total kudos`,
            label: (item, obj) => `${item.yLabel} activities`
          }
        }


        const dayOfWeekStatsChart = {
          data: Date.getDaysOfWeek(),
          callbacks: {
            title: (items, data) => `${items[0].xLabel} activities`,
            beforeBody: (items, data) => `${metricsPerDayOfWeek[items[0].xLabel].activities} activities, ${metricsPerDayOfWeek[items[0].xLabel].kudos} total kudos`,
            label: (item, obj) => {
              let unit = `${dayOfWeekStatsChart.data[item.datasetIndex].unit}`;
              let value = item.yLabel;
              if(unit === 'minutes'){
                unit = '';
                value = Date.formatTimespan(value*60);
              }
              return `Avg ${dayOfWeekStatsChart.data[item.datasetIndex].metric}: ${value} ${unit}`;
            }
          }
        }

        const dayOfWeekPerformanceStatsChart = {
          data: Date.getDaysOfWeek(),
          callbacks: {
            title: (items, data) => `${items[0].xLabel} activities`,
            beforeBody: (items, data) => `${metricsPerDayOfWeek[items[0].xLabel].activities} activities, ${metricsPerDayOfWeek[items[0].xLabel].kudos} total kudos`,
            label: (item, obj) => `Avg ${dayOfWeekPerformanceStatsChart.data[item.datasetIndex].metric}: ${item.yLabel} ${dayOfWeekPerformanceStatsChart.data[item.datasetIndex].unit}`
          }
        }

        const activityMovingTimeData = {};
        const activityElapsedTimeData = {};
        const activityDistanceData = {};
        const activityElevationData = {};

        const activitySpeedData = {};
        const activityHeartrateData = {};
        const activityWattsData = {};
        const activityWeightedWattsData = {};
        
        let hrDataPoints = {};
        let wattDataPoints = {};

        //aggregate all data into days of week
        fanData.activities && fanData.activities.forEach(activity => { 
          const date = new Date(activity.date.replace('Z', ''));
          activityKudosChart.data[date.toString()] = activity.kudos;
          if(activity.kudos > maxKudos) maxKudos = activity.kudos;
          //console.log(activity);

          activityMovingTimeData[date.toString()] = activity.movingTime/60;
          activityElapsedTimeData[date.toString()] = activity.elapsedTime/60;
          activityDistanceData[date.toString()] = Math.round((activity.distance/1609)*10)/10;
          activityElevationData[date.toString()] = Math.round((activity.totalElevationGain*3.281)*10)/10;

          activitySpeedData[date.toString()] = Math.round((activity.averageSpeed*3600/1609)*10)/10;
          activityHeartrateData[date.toString()] = activity.averageHeartrate;
          activityWattsData[date.toString()] = activity.averageWatts;
          activityWeightedWattsData[date.toString()] = activity.weightedAverageWatts;

          hrDataPoints[date.getDayOfWeek()] = hrDataPoints[date.getDayOfWeek()] || 0;
          wattDataPoints[date.getDayOfWeek()] = wattDataPoints[date.getDayOfWeek()] || 0;
          metricsPerDayOfWeek[date.getDayOfWeek()] = metricsPerDayOfWeek[date.getDayOfWeek()] || {};
          metricsPerDayOfWeek[date.getDayOfWeek()].kudos = (metricsPerDayOfWeek[date.getDayOfWeek()].kudos || 0) + activity.kudos;
          metricsPerDayOfWeek[date.getDayOfWeek()].activities = (metricsPerDayOfWeek[date.getDayOfWeek()].activities || 0) + 1;

          metricsPerDayOfWeek[date.getDayOfWeek()].movingTime = (metricsPerDayOfWeek[date.getDayOfWeek()].movingTime || 0) + activity.movingTime;
          metricsPerDayOfWeek[date.getDayOfWeek()].elapsedTime = (metricsPerDayOfWeek[date.getDayOfWeek()].elapsedTime || 0) + activity.elapsedTime;
          metricsPerDayOfWeek[date.getDayOfWeek()].distance = (metricsPerDayOfWeek[date.getDayOfWeek()].distance || 0) + activity.distance;
          metricsPerDayOfWeek[date.getDayOfWeek()].elevation = (metricsPerDayOfWeek[date.getDayOfWeek()].elevation || 0) + activity.totalElevationGain;

          metricsPerDayOfWeek[date.getDayOfWeek()].speed = (metricsPerDayOfWeek[date.getDayOfWeek()].speed || 0) + activity.averageSpeed;
          if(activity.averageHeartrate) hrDataPoints[date.getDayOfWeek()]++;
          metricsPerDayOfWeek[date.getDayOfWeek()].heartrate = (metricsPerDayOfWeek[date.getDayOfWeek()].heartrate || 0) + activity.averageHeartrate;
          if(activity.averageWatts) wattDataPoints[date.getDayOfWeek()]++;
          metricsPerDayOfWeek[date.getDayOfWeek()].watts = (metricsPerDayOfWeek[date.getDayOfWeek()].watts || 0) + activity.averageWatts;
          metricsPerDayOfWeek[date.getDayOfWeek()].weightedWatts = (metricsPerDayOfWeek[date.getDayOfWeek()].weightedWatts || 0) + activity.weightedAverageWatts;
          
        });

        activityChart.data = [
          { name: "Moving Time", metric: 'moving time', unit: 'minutes', data: activityMovingTimeData },
          { name: "Elapsed Time", metric: 'elapsed time', unit: 'minutes', data: activityElapsedTimeData },
          { name: "Distance", metric: 'distance', unit: 'miles', data: activityDistanceData },
          { name: "Elevation", metric: 'elevation', unit: 'ft', data: activityElevationData },
        ];

        activityPerformanceChart.data = [
          { name: "Speed", metric: 'speed', unit: 'mph', data: activitySpeedData },
          { name: "Heart Rate", metric: 'heartrate', unit: 'bpm', data: activityHeartrateData },
          { name: "Power", metric: 'power', unit: 'watts', data: activityWattsData },
          { name: "Weighted Power", metric: 'weighted power', unit: 'watts', data: activityWeightedWattsData },
        ];
        console.log(activitySpeedData);
    
        const movingTimeData = Date.getDaysOfWeek();
        const elapsedTimeData = Date.getDaysOfWeek();
        const distanceData = Date.getDaysOfWeek();
        const elevationData = Date.getDaysOfWeek();

        const speedData = Date.getDaysOfWeek();
        const heartrateData = Date.getDaysOfWeek();
        const wattsData = Date.getDaysOfWeek();
        const weightedWattsData = Date.getDaysOfWeek();
       
      
        //aggregate data into day buckets
        fanData.activities && fanData.activities.forEach(activity => { 
          const ptSize = maxPtSize * (activity.kudos / maxKudos);
          activityKudosChart.dataset.pointBorderColor.push(fanData.mostPopularActivities.slice(0, 3).find(act => act.activityId === activity.activityId) ? "#fff" : "");
          activityKudosChart.dataset.pointRadius.push(ptSize);
          activityKudosChart.dataset.pointHoverRadius.push(ptSize);
        

          const date = new Date(activity.date.replace('Z', ''));
          dayOfWeekKudosChart.data[date.getDayOfWeek()] = Math.round(metricsPerDayOfWeek[date.getDayOfWeek()].kudos/metricsPerDayOfWeek[date.getDayOfWeek()].activities);
          dayOfWeekActivitiesChart.data[date.getDayOfWeek()] = metricsPerDayOfWeek[date.getDayOfWeek()].activities;
          
          movingTimeData[date.getDayOfWeek()] = Math.round((metricsPerDayOfWeek[date.getDayOfWeek()].movingTime/60)/metricsPerDayOfWeek[date.getDayOfWeek()].activities);
          elapsedTimeData[date.getDayOfWeek()] = Math.round((metricsPerDayOfWeek[date.getDayOfWeek()].elapsedTime/60)/metricsPerDayOfWeek[date.getDayOfWeek()].activities);
          distanceData[date.getDayOfWeek()] = Math.round((metricsPerDayOfWeek[date.getDayOfWeek()].distance/1609)/metricsPerDayOfWeek[date.getDayOfWeek()].activities);
          elevationData[date.getDayOfWeek()] = Math.round(((metricsPerDayOfWeek[date.getDayOfWeek()].elevation*3.281)/metricsPerDayOfWeek[date.getDayOfWeek()].activities));
          
          speedData[date.getDayOfWeek()] = Math.round((metricsPerDayOfWeek[date.getDayOfWeek()].speed*3600/1609)/metricsPerDayOfWeek[date.getDayOfWeek()].activities);
          heartrateData[date.getDayOfWeek()] = Math.round((metricsPerDayOfWeek[date.getDayOfWeek()].heartrate)/hrDataPoints[date.getDayOfWeek()]);
          wattsData[date.getDayOfWeek()] = Math.round((metricsPerDayOfWeek[date.getDayOfWeek()].watts)/wattDataPoints[date.getDayOfWeek()]);
          weightedWattsData[date.getDayOfWeek()] = Math.round((metricsPerDayOfWeek[date.getDayOfWeek()].weightedWatts)/wattDataPoints[date.getDayOfWeek()]);
          
          dayOfWeekStatsChart.data = [
            { name: "Moving Time", metric: 'moving time', unit: 'minutes', data: movingTimeData },
            { name: "Elapsed Time", metric: 'elapsed time', unit: 'minutes', data: elapsedTimeData },
            { name: "Distance", metric: 'distance', unit: 'miles', data: distanceData },
            { name: "Elevation", metric: 'elevation', unit: 'ft', data: elevationData },
          ];
          dayOfWeekPerformanceStatsChart.data = [
            { name: "Speed", metric: 'speed', unit: 'mph', data: speedData },
            { name: "Heart Rate", metric: 'heartrate', unit: 'bpm', data: heartrateData },
            { name: "Power", metric: 'power', unit: 'watts', data: wattsData },
            { name: "Weighted Power", metric: 'weighted power', unit: 'watts', data: weightedWattsData },
          ]
        });
        return { activityKudosChart, activityChart, activityPerformanceChart, dayOfWeekKudosChart, dayOfWeekActivitiesChart, dayOfWeekStatsChart, dayOfWeekPerformanceStatsChart };
    }

   
    render(){
        const { fanData } = this.props;
        const { activityKudosChart, activityChart, activityPerformanceChart, dayOfWeekKudosChart, dayOfWeekActivitiesChart, dayOfWeekStatsChart, dayOfWeekPerformanceStatsChart } = this.calculateChartData(fanData);
        return (
        <div>
            <Row>
            <Col>
                <span className="section-title">Most Kudoed Activities</span>
                <ul className="top-activities">
                {fanData.mostPopularActivities && fanData.mostPopularActivities.slice(0,3).map((activity, i) => (
                    <li key={i}>
                    <span className="activity-date">{new Date(activity.date).toLocaleDateString()}</span>
                    <span className="activity-kudos"><FontAwesomeIcon icon="thumbs-up" /> {activity.kudos} Kudos</span>
                    <span className="activity-name"><a target="activity" href={`https://www.strava.com/activities/${activity.activityId}`}><FontAwesomeIcon icon={i === 0 ? "crown" : "trophy"} /> {activity.activity}</a></span>
                    </li>
                ))}
                </ul>   
            </Col>
            </Row>
            {fanData.activities &&
            <Row>
            <Col>
                <span className="section-title">Analysis</span>
                <div className="">Activity Kudos</div>
                <LineChart data={activityKudosChart.data}  colors={["#ea5a29"]} height="150px" width={Math.min(this.state.width - 50, 768) }
                dataset={activityKudosChart.dataset}  
                library={{
                  scales: { yAxes: [ { id: 'y-axis', gridLines: { display: false } } ]}, 
                  tooltips: { callbacks: { title: activityChart.callbacks.title, beforeBody: activityKudosChart.callbacks.beforeBody, label: activityKudosChart.callbacks.label }}
                }} />

                <div className="section-subtitle">Average Kudos/Day of Week</div>
                <ColumnChart data={dayOfWeekKudosChart.data} colors={["#ea5a29"]} height="150px" width={Math.min(this.state.width - 50, 768) }
                library={{
                  scales: { yAxes: [ { id: 'y-axis', gridLines: { display: false } } ]}, 
                  tooltips: { callbacks: dayOfWeekKudosChart.callbacks }
                }} />

                <div className="section-subtitle">Activity Stats</div>
                <LineChart data={activityChart.data}  colors={["#ea5a29", "#ea8a29", "#eaaa29", "#eada29"]} height="250px" width={Math.min(this.state.width - 50, 768) }
                library={{
                  scales: { yAxes: [ { id: 'y-axis', type: 'logarithmic', gridLines: { display: false }, ticks: { sampleSize: 10, labelOffset: -5 } } ]}, 
                  tooltips: { callbacks: { title: activityChart.callbacks.title, beforeBody: activityChart.callbacks.beforeBody, label: activityChart.callbacks.label }}
                }} />
                
                <div className="section-subtitle">Activity Performance</div>
                <LineChart data={activityPerformanceChart.data}  colors={["#ea5a29", "#ea8a29", "#eaaa29", "#eada29"]} height="250px" width={Math.min(this.state.width - 50, 768) }
                library={{
                  scales: { yAxes: [ { id: 'y-axis', gridLines: { display: false } } ]}, 
                  tooltips: { callbacks: { title: activityChart.callbacks.title, beforeBody: activityPerformanceChart.callbacks.beforeBody, label: activityPerformanceChart.callbacks.label }}
                }} />

                <div className="section-subtitle">Activities/Day of Week</div>
                <ColumnChart data={dayOfWeekActivitiesChart.data} colors={["#ea5a29"]} height="150px" width={Math.min(this.state.width - 50, 768) }
                library={{
                  scales: { yAxes: [ { id: 'y-axis', gridLines: { display: false } } ]}, 
                  tooltips: { callbacks: dayOfWeekActivitiesChart.callbacks }
                }} />

                <div className="section-subtitle">Activity Stats/Day of Week</div>
                <LineChart data={dayOfWeekStatsChart.data} colors={["#ea5a29", "#ea8a29", "#eaaa29", "#eada29"]} height="150px" width={Math.min(this.state.width - 50, 768) }
                library={{
                  scales: { yAxes: [ { id: 'y-axis', type: 'logarithmic', gridLines: { display: false } } ]}, 
                  tooltips: { callbacks: dayOfWeekStatsChart.callbacks }
                }} />

               <div className="section-subtitle">Performance Stats/Day of Week</div>
                <LineChart data={dayOfWeekPerformanceStatsChart.data} colors={["#ea5a29", "#ea8a29", "#eaaa29", "#eada29"]} height="250px" width={Math.min(this.state.width - 50, 768) }
                library={{
                  scales: { yAxes: [ { id: 'y-axis', type: 'logarithmic', gridLines: { display: false } } ]}, 
                  tooltips: { callbacks: dayOfWeekPerformanceStatsChart.callbacks }
                }} />
               
               
            </Col>
            </Row>}
        </div>
        );
    }
}

export default MostPopularActivities;