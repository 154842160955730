import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Progress, ButtonGroup, Button, Alert } from 'reactstrap';
import connectToStrava from '../content/btn_strava_connectwith_orange/btn_strava_connectwith_orange.svg';

class Connection extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }
    
    render(){
        const { hasToken, error, isLoading, isLoaded, onSetRangeQuery, onGetFans, rangeQuery } = this.props;
        return (
            <div className="connection">
            {error &&
              <div className="error">
                 <Alert color="danger" className="alert-orange">
                  <FontAwesomeIcon icon="exclamation-triangle" /> {error.message}
                </Alert>  
              </div>
            }
            {!hasToken && !isLoading && 
              <a href={`/api/auth`}><img src={connectToStrava} alt="Connect to Strava" /></a>
            }
            {hasToken && !isLoading &&
              <div>
                <ButtonGroup>
                  <Button className="small" onClick={() => onSetRangeQuery('last=100')} active={rangeQuery === 'last=100'}>Last 100</Button>
                  <Button className="small" onClick={() => onSetRangeQuery(`date=1/1/${new Date().getFullYear()}`)} active={rangeQuery === `date=1/1/${new Date().getFullYear()}`}>Current Year</Button>
                  <Button className="small" onClick={() => onSetRangeQuery('')} active={rangeQuery === ''}>Year-To-Date</Button>
                </ButtonGroup>
                <br/>
                <button type="button" className="big-btn btn" onClick={onGetFans}>{!isLoaded ? "Check My Status!" : "Update My Status!" }</button>
              </div>
            }
            {isLoading &&
              <div className="loading">
                <Progress animated value={100} color="#ea5a29">Loading... Please wait.</Progress>
              </div>
            }
            </div>
        );
    }
}

export default Connection;