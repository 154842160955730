import React from 'react';
import LegendStatusDashboard from '../Containers/LegendStatusDashboard';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import { Container } from 'reactstrap';

const Home = () => {

    return (
        <div className="App">
            <Container>
                <Header />
                <section className="content">
                    <LegendStatusDashboard />
                </section>
                <Footer />
            </Container>
        </div>
    );
}

export default Home;