import React from 'react';
import { Row, Col } from 'reactstrap';

class AthleteProfile extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }
   
    render(){
        const { athleteData } = this.props;
        return (
          <Row>
            <Col xs="12">
              <span className="athlete-profile">
                <img src={athleteData.profile} alt="Profile" />
              </span>
              <div className="athlete-info">
                <span className="athlete-name">
                  {athleteData.firstname} {athleteData.lastname}
                </span>
                <span className="athlete-details">
                  {athleteData.city}, {athleteData.state}, {athleteData.country}
                </span>
              </div>
            </Col>
          </Row>
        );
    }
}

export default AthleteProfile