

const applyArrayExtensions = () => {
    /* eslint-disable-next-line no-extend-native */
    Array.prototype.chunk = function ( n ) {
        if ( !this.length ) {
            return [];
        }
        return [ this.slice( 0, n ) ].concat( this.slice(n).chunk(n) );
    }
};

const applyDateExtensions = () => {
    /* eslint-disable-next-line no-extend-native */
    Date.prototype.getDayOfWeek = function(){   
        return ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"][ this.getDay() ];
    };
    
    /* eslint-disable-next-line no-extend-native */
    Date.getDaysOfWeek = function(){
        return { "Monday": null, "Tuesday": null, "Wednesday": null, "Thursday": null, "Friday": null, "Saturday": null, "Sunday": null };
    }

    Date.formatTimespan = function(totalSeconds){
        const hours = Math.floor(totalSeconds / 60 / 60);
        const minutes = Math.floor(totalSeconds / 60) - (hours * 60);
        const seconds = totalSeconds % 60;
        
        //const formatted = `${hours.toString()} hrs, ${minutes.toString()} min, ${seconds.toString()} sec`; 
        const formatted = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
        
        return formatted;
    }
};
export { applyArrayExtensions, applyDateExtensions };
